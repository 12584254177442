import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_work_together.svg';

function ServicesWebappsPage() {

    return <ContentPage>
        <h1>Web Applications</h1>
        <div className='clearfix'>
        <PageImage image={image} alt='Web development' leftAlign={true} />
        <p>Web applications are programs that run in the web.  Unlike traditional websites, users are not limited just 
            to reading static pages and consuming content.  Instead, they can interact with the application with the 
            features and richness of experience that was previously only available on a desktop.</p>
        <p>At Coralpeak systems, we build web applications that can deliver everything that a desktop application can, 
            but with additional features that are not possible from the desktop.</p>
        </div>
        <h2>Run your business application natively in a web browser</h2>
        <p>Unless you have an application that requires extreme processing power, it can be run in a web browser. In 
            recent years, browsers have evolved into platforms that rival native desktops for flexibility and features.</p>
        <h2>Nothing to install</h2>
        <p>The web application runs in a web browser. Any web browser. There is nothing to install, update, or maintain; 
            everything that the application needs is available through the browser.</p>
        <h2>Runs on all devices with a browser</h2>
        <p>The application will run in any browser on any device, whether that is a workstation, a laptop, a tablet, a 
            smartphone, or a smart TV. Our applications automatically optimise their layout for the screen size and 
            format to ensure that the right information and controls are always available and easy to use.</p>
        <h2>Global access and collaboration</h2>
        <p>A web application can be run anywhere with an internet connection. The data is shared and securely accessible 
            globally.  Your users can work with the application in the office, at home, or on the move. The data is 
            updated instantly whenever changes are made, so users everywhere see them at the same time.</p>
        <h2>Global overview for management and reporting</h2>
        <p>The global data in a web application is available from all locations, so managers can monitor the business 
            and take decisions based on up-to-date data.</p>
        <h2>Optimise running costs</h2>
        <p>Our web applications usually run in the cloud. This means that you only pay for the services that you need. 
            There is no need to set up, secure, and maintain expensive hardware. We can help you select the optimum 
            location to host your application depending on the spread of your users.</p>
        <p>Of course, our systems can instead be hosted in your company data centre if you prefer.</p>

    </ContentPage>

}

export default ServicesWebappsPage;